<template lang="pug">
app-layout
  v-overlay.text-center(
    :value="loading"
    color="#fff"
    absolute
    opacity="1"
  )
    ui-loader(text="Загрузка...")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import { UiLoader } from '@/uikit';

  export default {
    name: 'page-sbbol-auth',
    components: { AppLayout, UiLoader },

    data() {
      return {
        baseUrl: `${process.env.VUE_APP_API_HOST}/api/v2/oauth/sbbol`,
        callbackUrl: `${process.env.VUE_APP_HOST}/oauth/sbbol/callback`,
      };
    },

    computed: {
      url() {
        return `${this.baseUrl}?callback_url=${this.callbackUrl}`;
      },
    },

    mounted() {
      window.location.href = this.url;
    },
  };
</script>
